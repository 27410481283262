import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title = "", children }) => {
  const rootPath = `/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div className="main-heading">
        <div>
          <h1 className="main-heading__title">
            <Link to="/">Ryan Hyslop</Link>
          </h1>
          <h2 className="main-heading__strapline">
            Contract Lead Web &amp; React Native Developer
          </h2>
        </div>

        {/* <nav className="main-navigation">
          <ul>
            <li>
              <a href="">Blog</a>
            </li>
            <li>
              <a href="">Work</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
          </ul>
        </nav> */}
      </div>
    )
  } else {
    header = (
      <div className="main-heading">
        <div>
          <h1 className="main-heading__title">
            <Link to="/">Ryan Hyslop</Link>
          </h1>
          <h2 className="main-heading__strapline">
            Contract Lead Web &amp; React Native Developer
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>© {new Date().getFullYear()} Ryan Hyslop </footer>
    </div>
  )
}

export default Layout
